export * from './modelInstantMessaging';
export * from './modelTvshowsReplacementOffer';

import gql from 'graphql-tag';
import {
    FRAGMENT_ALBUM_PREVIEW_PICTURES,
    FRAGMENT_ALBUM_SALES_DATA,
    FRAGMENT_AUTH_USER,
    FRAGMENT_AVATAR,
    FRAGMENT_BA6_DATA,
    FRAGMENT_CMS_ARTICLE,
    FRAGMENT_LOCALIZED_TEXT,
    FRAGMENT_MEDIA_ALBUM,
    FRAGMENT_NOTIFICATION_ITEMS,
    FRAGMENT_PROFILE_PREVIEW_PICTURE,
    FRAGMENT_TERM_ACCEPTANCE,
    FRAGMENT_VIDEO_PREVIEW,
} from '../fragments';
import {TermsTypeEnum} from '../types';

export const QUERY_APP_INITIAL = gql`
    query APP_INITIAL($notificationsOffset: Int = 0, $notificationsLimit: Int, $timezone: String!) {
        auth {
            ...AuthUser
        }
        model {
            isStudio
            hasWebmasterId
            isMobileRegistration
            instantMessaging {
                account(platform: TELEGRAM) {
                    active
                }
            }
            abo {
                token
            }
            contests {
                contests {
                    status
                    uploadEnd
                }
            }
            avs {
                status
            }
            documents {
                allActorDocumentsComplete
                allActorDocumentsUploaded
                allOriginatorDocumentsUploaded
                hasAllRequiredDocuments
            }
            hasActors
            hasOriginator
            avatar {
                ...Avatar
            }
            account {
                email
                isVerified
            }
            newscenterNotifications: notifications(
                unreadOnly: true
                showExpired: true
                category: [newscenter, system, snagbar, vxgames2020, vxchallenges]
                timezone: $timezone
            ) {
                count
            }
            extra {
                picturePoolUmaId
                mailingsUmaId
                showMenuItemActors
                isNewRegistrationWithoutVerifiedEmail
            }
            vxcash {
                wmid
                autologinLink
            }
            profile {
                languages{value}
                laSettingsProfileActive
                gender {
                    value
                }
            }
            terms {
                acceptanceVXModelsTermsAndConditions: acceptance(type: ${TermsTypeEnum.VXMODELS_TERMS_AND_CONDITIONS}) {
                    ...TermAcceptance
                }
                acceptancePrivacyPolicy: acceptance(type: ${TermsTypeEnum.VXMODELS_PRIVACY_POLICY}) {
                    ...TermAcceptance
                }
                acceptanceTelegramTermsAndConditions: acceptance(type: ${TermsTypeEnum.TELEGRAM_TERMS}) {
                    ...TermAcceptance
                }
            }
            unreadNotifications: notifications(
                showExpired: false
                unreadOnly: true
                category: [
                    common
                    directNotification
                    document
                    finance
                    marketing
                    medal
                    mediacontent
                    homepage
                    profile
                    ranking
                    system
                ]
                timezone: $timezone
            ) @connection(key: "notifications", filter: ["showExpired", "unreadOnly", "category"]) {
                count
                items(sort: PRIO_DESC__DATE_DESC, offset: $notificationsOffset, limit: $notificationsLimit)
                @connection(key: "items", filter: ["sort"]) {
                    ...NotificationItems
                }
            }
            bonuscodes {
                canCreateVisitXBonusCodes
            }
            media {
                hasSubmittedAlbumsForDailyBonus
                dailyBonusOptInStatus
                previewPicture16 {
                    picture {
                        isChecked
                    }
                }
            }
            permissions {
                isFinancesVisibleWithoutMasterPassword
                isChatPricesVisible
            }
            vxChallenges {
              isAllowed
            }
        }
    }
    ${FRAGMENT_AUTH_USER}
    ${FRAGMENT_NOTIFICATION_ITEMS}
    ${FRAGMENT_AVATAR}
    ${FRAGMENT_TERM_ACCEPTANCE}
`;

export const QUERY_VERIFY_PASSWORD_HASH = gql`
    query QUERY_VERIFY_PASSWORD_HASH($hash: String!) {
        verifyPasswordResetHash(hash: $hash)
    }
`;

export const QUERY_MODEL_VIDEOS_ALBUMS_BY_TYPE = gql`
    query QUERY_MODEL_VIDEOS_ALBUMS_BY_TYPE(
        $type: MediaType
        $offset: Int
        $limit: Int
        $sortField: MediaAlbumSortFields
        $sortDirection: MediaAlbumSortDirection
        $imported: Boolean
    ) {
        model {
            videos(
                type: $type
                offset: $offset
                limit: $limit
                sortField: $sortField
                sortDirection: $sortDirection
                imported: $imported
            ) {
                albumsTotal
                albums {
                    ...VideoPreview
                }
            }
        }
    }
    ${FRAGMENT_VIDEO_PREVIEW}
`;

export const QUERY_CMS_ARTICLE = gql`
    query QUERY_CMS_ARTICLE($id: ID!, $rev: Int = 0, $lang: ApiLang) {
        cms(lang: $lang) {
            article(id: $id, rev: $rev) {
                ...CmsArticle
            }
        }
    }

    ${FRAGMENT_CMS_ARTICLE}
`;

export const QUERY_PHOTO_ALBUMS_BY_TYPE = gql`
    query GET_ALBUMS_BY_TYPE(
        $type: MediaType
        $offset: Int
        $limit: Int
        $sortField: MediaAlbumSortFields
        $sortDirection: MediaAlbumSortDirection
    ) {
        model {
            photoAlbums(
                type: $type
                offset: $offset
                limit: $limit
                sortField: $sortField
                sortDirection: $sortDirection
            ) {
                albumsTotal
                albums {
                    ...MediaAlbumItem
                }
            }
        }
    }
    ${FRAGMENT_MEDIA_ALBUM}
`;

export const QUERY_MODEL_MAILINGS_MAILINGS = gql`
    query QUERY_MODEL_MAILINGS_MAILINGS(
        $type: MailingTypeEnum!
        $recipientsGroups: [MailingRecipientsGroupsEnum!]
        $status: [MailingStatusEnum!]
        $limit: Int
    ) {
        model {
            mailings {
                mailings(
                    type: $type,
                    group: $recipientsGroups,
                    status: $status
                    limit: $limit,
                ) {
                    id
                    name
                    type
                    status
                    editable
                    shipmentRecipientsCount
                    created
                    modified
                    published
                }
            }
        }
    }
`;

export const QUERY_AUTH_MODEL_GENDER_AVATAR = gql`
    query QUERY_AUTH_MODEL_GENDER_AVATAR {
        auth {
            username
            screenname
            id
            hotlineCode
            roles
        }
        model {
            account{
                keyAccounter {
                    contactEmail
                    contactName
                    contactPhoneNumber
                }
            }
            profile {
                gender {
                    value
                }
            }
            avatar {
                ...Avatar
            }
        }
    }
    ${FRAGMENT_AVATAR}
`;

export const QUERY_MODEL_ABO_TOKEN = gql`
    {
        model {
            abo {
                token
            }
        }
    }
`;

export const QUERY_MODEL_PROFILE_FETISH = gql`
    query QUERY_MODEL_PROFILE_FETISH {
        model {
            profile {
                isFetishUnlockedBySupport

                fetishGaysSpecials {
                    value
                    formData {
                        value
                        label
                    }
                }
                fetishExperiences {
                    value
                    formData {
                        value
                        label
                    }
                }
                fetishPractices {
                    value
                    formData {
                        value
                        label
                    }
                }
                fetishTaboos {
                    hasTexts
                    texts {
                        language
                        text
                    }
                    ratingStatus
                    rejectionReason
                    newsletterStatus
                }
                fetishState {
                    value
                    formData {
                        value
                        label
                    }
                }

                fetishToys {
                    value
                    formData {
                        value
                        label
                    }
                }

                fetishRole {
                    value
                    formData {
                        value
                        label
                    }
                }

                fetishText {
                    hasTexts
                    texts {
                        language
                        text
                    }
                    ratingStatus
                    rejectionReason
                    newsletterStatus
                }
            }
        }
    }
`;

export const QUERY_MODEL_PROFILE_LANGUAGES = gql`
    query QUERY_MODE_PROFILE_LANGUAGES {
        model {
            profile {
                languages {
                    value
                }
            }
        }
    }
`;

export const QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD = gql`
    query QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD($field: SignupField!, $value: Any!) {
        validation {
            validateSignupField(field: $field, value: $value) {
                isValid
                messages
            }
        }
    }
`;

export const QUERY_MODEL_MEDIA_DAILY_BONUS_OPT_IN_STATUS = gql`
    query QUERY_MODEL_MEDIA_DAILY_BONUS_OPT_IN_STATUS {
        model {
            media {
                dailyBonusOptInStatus
            }
        }
    }
`;

export const QUERY_MODEL_PHOTOALBUMS_SCHEDULING = gql`
    query QUERY_PHOTOALBUMS_SCHEDULING($id: Int!) {
        model {
            photoAlbums(id: $id) {
                albums {
                    id
                    dailyBonus {
                        modelContent {
                            contentId
                            contentType
                            selectedFor
                            preSelectedFor {
                                from
                                to
                            }
                        }
                    }
                    vip30Scheduling {
                        month
                        year
                    }
                }
            }
        }
    }
`;

export const QUERY_MODEL_VIDEOS_ALBUM = gql`
    query QUERY_MODEL_VIDEOS_ALBUM($id: Int!) {
        model {
            videos(id: $id) {
                albums {
                    video {
                        originalFilename
                        title
                        duration
                    }
                    ...AlbumSalesData
                    ...AlbumPreviewPictures
                }
            }
        }
    }
    ${FRAGMENT_ALBUM_SALES_DATA}
    ${FRAGMENT_ALBUM_PREVIEW_PICTURES}
`;

export const QUERY_MODEL_SERVICE0900 = gql`
    query QUERY_MODEL_SERVICE0900 {
        model {
            service0900 {
                status
                allowedCountries {
                    countryCodeAlpha2
                    countryCallingCode
                }

                visitx0900Numbers {
                    baseNumber
                    ddi
                    fullNumber
                    countryCodeAlpha2
                }

                targetNumber {
                    ...Service0900TargetNumber
                }

                settings {
                    voice
                    sexuality
                    description {
                        languages
                        texts {
                            ...LocalizedText
                        }
                    }
                }

                phoneCarousel {
                    targetNumbers {
                        id
                        countryCallingCode
                        phoneNumber
                        fullPhoneNumber
                    }
                }
            }
        }
    }
    ${FRAGMENT_BA6_DATA}
    ${FRAGMENT_LOCALIZED_TEXT}
`;

export const QUERY_MODEL_MEDIA_FSK_PREVIEW_PICTURES = gql`
    query QUERY_MODEL_MEDIA_FSK_PREVIEW_PICTURES {
        model {
            media {
                previewPicture12 {
                    album {
                        id
                    }
                    picture {
                        url
                        id
                        isChecked
                    }
                }
                previewPicture16 {
                    album {
                        id
                    }
                    picture {
                        url
                        id
                        isChecked
                    }
                }
            }
        }
    }
`;

export const QUERY_WELCOME_CONTENT = gql`
    query QUERY_WELCOME_CONTENT {
        model {
            avatar {
                ...Avatar
            }
            media {
                previewPicture16 {
                    ...PreviewPicture
                }
            }
            tvshows {
                enabled
                planned {
                    start
                }
                links {
                    chat
                    calendar
                }
            }
            documents {
                allActorDocumentsComplete
                allActorDocumentsUploaded
                allOriginatorDocumentsUploaded
                allActorDocumentsComplete
            }
        }
    }
    ${FRAGMENT_AVATAR}
    ${FRAGMENT_PROFILE_PREVIEW_PICTURE}
`;

export const QUERY_ATLEASTONEACTORDOC = gql`
    query QUERY_ATLEASTONEACTORDOC {
        model {
            documents {
                atLeastOneActorDocumentUploaded
            }
        }
    }
`;


export const AVAILABLE_MIN_AMOUNTS = gql`
    query AVAILABLE_MIN_AMOUNTS {
        model {
            bonuscodes {availableContentMinAmounts {value label}}
        }
    }

`;

export const QUERY_MODEL_AVATAR = gql`
    query QUERY_MODEL_AVATAR {
        model {
            avatar {
                ...Avatar
            }
        }
    }
    ${FRAGMENT_AVATAR}
`;

export const QUERY_MODEL_PREVIEW_PICTURE16 = gql`
    query QUERY_MODEL_PREVIEW_PICTURE16 {
        model {
            media {
                previewPicture16 {
                    ...PreviewPicture
                }
            }
        }
    }
    ${FRAGMENT_PROFILE_PREVIEW_PICTURE}
`;

export const QUERY_MODEL_TVSHOWS_ENABLED = gql`
    query QUERY_MODEL_TVSHOWS_ENABLED {
        model {
            tvshows {
                enabled
            }
        }
    }
`;

export const MODEL_VIDEOS_ALBUMS_FEEDBACK = gql`
    query MODEL_VIDEOS_FEEDBACK($umaId: Int) {
        model {
            avatar {
                ...Avatar
            }
            videos(id: $umaId) {
                albums {
                    video {
                        title
                        originalFilename
                    }
                    dislikes
                    likes
                    groupedLikes(limit: 3, avatarSize: w60) {
                        userId
                        userName
                        avatarUrl
                    }
                    feedbacksItems {
                        id
                        guestLogin
                        guestAvatar
                        answer
                        text
                        date
                        isAnswered
                    }
                }
            }
        }
    }
    ${FRAGMENT_AVATAR}
`;

export const QUERY_MODEL_TOOLTIPSETTINGS_HASSEEN = gql`
    query QUERY_MODEL_TOOLTIPSETTINGS_HASSEEN($type: TooltipType) {
        model {
            tooltipSettings {
                hasSeen(type: $type)
            }
        }
    }
`;

export const QUERY_MODEL_DOCUMENTS_UPLOADASSISTANT_FLOW = gql`
    query QUERY_MODEL_DOCUMENTS_UPLOADASSISTANT_FLOW($flowType: DocumentUploadAssistantTypeEnum!, $flowStep: DocumentUploadAssistantStepEnum, $userId: Int) {
        model {
            documents {
                uploadAssistant {
                    flow(flowType: $flowType, flowStep: $flowStep, userId: $userId) {
                        userId
                        firstname
                        lastname
                        allStepsComplete
                        steps {
                            name
                            status
                            documentType
                            documentTypeId
                            uploadUrl
                            templateUrl
                            exampleUrl
                        }
                        currentStep {
                            name
                            status
                            documentType
                            documentTypeId
                            uploadUrl
                            templateUrl
                            exampleUrl
                        }
                    }
                }
            }
        }
    }
`;

export const QUERY_HOOK_AUTH_DEEPLINK_BETA_FEED = gql`
    query QUERY_HOOK_AUTH_DEEPLINK($target: VXModelsMobileTargetEnum!) {
        auth {
            id
            betaDeeplink(target:$target)
        }
    }
`
