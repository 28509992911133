import React, { FC } from 'react';
import { AdmailsItem, BonusCodesItem } from '../items';

type TProps = {
}

const MarketingGroup: FC<TProps> = ({}) => (
  <>
    <BonusCodesItem/>
    <AdmailsItem/>
  </>
);

export default MarketingGroup;
