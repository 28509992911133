import React, { FunctionComponent } from 'react';
import {
  MediaAlbum,
  MediaPicture,
  VXGChallengeTypeEnum,
  VXGChallengeUserStatusEnum,
} from '../../../graphql/VXModels/types';
import { Container, Grid } from '@material-ui/core';
import { UserData } from '../../../util/UserData';
import { TopAmateur } from '../WidgetsLarge';
import Calendar from '../Calendar/Calendar';
import { WelcomeBack } from '../WidgetsMedium';
import { _ } from '../../../util/translate';
import { Spinner } from '../../../components';
import GridTileItem from '../../../atoms/DashboardTile/GridTileItem';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import styled from '@emotion/styled';
import { VXGamesBanner } from '../WidgetsXL';
import NewVXMSwitch2 from '../Notifications/NewVXMSwitch2';

interface OwnProps {
  loading: boolean;
  dashboard: {
    chatRating: number;
    chatHoldingTime: number;
    onlineTimeCurrentMonth: number;
    payableAmount: number;
  };
  userPrivileged: boolean;
  wasOnlineWithSSW: boolean;
  isOnlineWithSSW: boolean;
  picture: never | MediaPicture;
  album: never | MediaAlbum;
  commonProfileFillingPercentage: number;
  userData: UserData;
  hasTopAmateurSlots: number | boolean;
  tvshows: never;
  topAmateur: never;
  vxChallenge: {
    type: VXGChallengeTypeEnum;
    targetValue: number;
    userStatistic: {
      status: VXGChallengeUserStatusEnum;
      actualValue: number;
      isTocAccepted: boolean;
    };
    texts: { title: string; unit: string };
  };
  vxgamesAllowed: boolean;
}

const DashboardSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = OwnProps;

const dashboardSpinner = (size: string) => {
  return (
    <DashboardSpinner>
      <Spinner inline={true} size={size}/>
    </DashboardSpinner>
  );
};

const VXModelsDashboard: FunctionComponent<Props> = ({
                                                       loading,
                                                       dashboard,
                                                       userPrivileged,
                                                       wasOnlineWithSSW,
                                                       commonProfileFillingPercentage,
                                                       hasTopAmateurSlots,
                                                       tvshows,
                                                       topAmateur,
                                                       vxChallenge,
                                                       vxgamesAllowed,
                                                     }: OwnProps) => {

  const showMessengerStat = userPrivileged;
  const tvshow = tvshows?.enabled;
  const isTopModel = hasTopAmateurSlots;

  // visible elements
  const b1VXGamesBannerVisible = vxChallenge?.userStatistic.isTocAccepted && vxgamesAllowed;

  const b3WelcomeBackTileVisible = wasOnlineWithSSW && !showMessengerStat && !(isTopModel && tvshow);
  const b3TopAmateurTileVisible = wasOnlineWithSSW && isTopModel;
  const b3CalendarTileVisible = wasOnlineWithSSW && tvshow;
  const b3VXModelsSwitchNewVisible = true;

  // size calculation
  const b3TileCount = 0
    + (b3WelcomeBackTileVisible ? 1 : 0)
    + (b3TopAmateurTileVisible ? 1 : 0)
    + (b3CalendarTileVisible ? 1 : 0);
  const b3XLCols = Math.max(12 / 4, 12 / b3TileCount); // max is 4 tiles per row

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="stretch">

        {/*** Block 1 ***/}

        {/* VXGames Banner */}
        {b1VXGamesBannerVisible && (
          <GridTileItem
            xs={12}
            noHeader={true}
            noSmallCardLayout={true}
            content={loading ? dashboardSpinner('s') :
              <VXGamesBanner vxChallenge={vxChallenge}/>
            }
          />
        )}

        {/*** Block 3 ***/}

        {/* Welcome Back Tile */}
        {b3WelcomeBackTileVisible && (
          <GridTileItem
            xl={b3XLCols}
            md={6}
            xs={12}
            title={_('dashboard:app.welcomeBack.header')}
            content={loading ? dashboardSpinner('m') :
              <WelcomeBack loading={false}/>
            }
          />
        )}

        {/* Top Amateur Tile */}
        {b3TopAmateurTileVisible && (
          <GridTileItem
            xl={b3XLCols}
            md={6}
            xs={12}
            icon={<RecentActorsIcon fontSize="large"/>}
            title={_('dashboard:app.topAmateur.title')}
            content={loading ? dashboardSpinner('m') :
              <TopAmateur data={topAmateur}/>
            }
          />
        )}

        {/* Calendar Tile */}
        {b3CalendarTileVisible && (
          <GridTileItem
            xl={b3XLCols}
            md={6}
            xs={12}
            icon={<span className="icon-calendar"/>}
            title={_('dashboard:app.calendar.header')}
            content={loading ? dashboardSpinner('m') :
              <Calendar loading={false}
                        {...tvshows}
              />
            }
          />
        )}

        {b3VXModelsSwitchNewVisible && (
          <GridTileItem
            xs={12}
            noCardLayout={true}
            content={loading ? dashboardSpinner('m') :
              <NewVXMSwitch2 />
            }
          />
        )}

      </Grid>

    </Container>
  );
};

export default VXModelsDashboard;
