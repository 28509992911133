import React from 'react';
import { Redirect, Switch } from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { stripSlash } from '../../util/urlHelper';

import LA from './LA';

const OnlineProfile = ({ match: { url } }) => {
  const path = stripSlash(url);

  return (
    <Switch>
      <ProtectedRoute path={`${path}/lustagenten`} component={LA} />
      <Redirect to='/dashboard' />
    </Switch>
  );
};
export default OnlineProfile;
