import React, { FC } from 'react';
import {
  LustagentenProfileItem,
} from '../items';

type TProps = {
}

const ProfileGroup: FC<TProps> = () => (
  <LustagentenProfileItem/>
);

export default ProfileGroup;
