import React from 'react';
import { Redirect, Switch } from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { stripSlash } from '../../util/urlHelper';

import Admails from './Admails/Admails';

const Marketing = ({ match: { url } }) => {
  const path = stripSlash(url);

  return (
    <Switch>
      <ProtectedRoute path={`${path}/admails`} component={Admails} />
      <Redirect to={`${path}/admails`} />
    </Switch>
  );
};
export default Marketing;
