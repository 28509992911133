import React, { FC } from 'react';
import { LeftMenuRoot } from '../../components';
import {
  MarketingGroup,
  ProfileGroup,
  TelegramGroup,
} from '../groups';
import {
  DashboardItem,
  HelpItem,
  Service0900Item,
  VXGamesItem,
} from '../items';
import { useUserData } from '../../../../util/UserData';

const LeftMenuVXModels: FC = () => {
  const { model } = useUserData();
  const vxgamesAllowed = model?.vxChallenges.isAllowed;

  return (
    <LeftMenuRoot>
      <DashboardItem />
      <ProfileGroup />
      <TelegramGroup />
      <MarketingGroup />
      <Service0900Item />
      {vxgamesAllowed && <VXGamesItem />}
      <HelpItem />
    </LeftMenuRoot>
  );
};

export default LeftMenuVXModels;
